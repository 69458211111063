import React, { useEffect, useState, Suspense, lazy } from "react";
import mixpanel from "mixpanel-browser";
import TagManager from "react-gtm-module";
import { Navigate, Route, Routes } from "react-router-dom";
import SurveyForm from "./SurveyForm";
import Dialog from "./ui/primitives/Dialog";
import "./ui/styles/main.css";
import "./ui/styles/tailwind.css";
// import ComponentLibrary from "./ui/views/ComponentLibrary";
import NotFound from "./ui/views/ErrorViews/404/NotFound";
import SomethingWrong from "./ui/views/ErrorViews/500";
import PrivateRoute from "./ui/views/Routes/PrivateRoute";
import PublicRoute from "./ui/views/Routes/PublicRoutes";
import ConnectionProblem from "./ui/views/onBoarding/ConnectionProblem";
import ForgetPassword from "./ui/views/onBoarding/components/ForgetPassword";
import Login from "./ui/views/onBoarding/components/Login";
import ResetPassword from "./ui/views/onBoarding/components/ResetPassword";
import DeleteAccountSurvey from "./ui/views/surveyView/DeleteAccountSurvey";
import ICFSurvey from "./ui/views/surveyView/ICFSurvey";
import WaitlistSurvey from "./ui/views/surveyView/WaitlistSurvey";
import { Toaster } from "react-hot-toast";
import useWindowSize from "./hooks/useWindowSize";
import Signup from "./ui/primitives/forms/registration/Signup";
import NewPlatformRedirect from "./ui/views/onBoarding/NewPlatformRedirect";
import Loader from "./ui/primitives/Loader/Loader";
import DeleteAccount from "./ui/primitives/dialogContent/deleteAccount";
import MessageDelivered from "./ui/views/messageDeliveredView/MessageDelivered";

// Lazy load the components
const PersonalizeBulletproof = lazy(
  () => import("./ui/primitives/forms/registration/PersonalizeBulletproof"),
);
const LinkAccount = lazy(() => import("./ui/views/onBoarding/LinkAccount"));
// const MessageDelivered = lazy(
//   () => import("./ui/views/messageDeliveredView/MessageDelivered"),
// );
const SettingsView = lazy(() => import("./ui/views/settingsView/SettingsView"));
const SurveyView = lazy(() => import("./ui/views/surveyView/surveyView"));
const ContactView = lazy(() => import("./ui/views/contactsView/contactView"));
const Profile = lazy(() => import("./ui/views/profileView/Profile"));
const Subscription = lazy(() => import("./ui/views/SubscriptionView/Subscription"));
const PaymentMethod = lazy(() => import("./ui/views/SubscriptionView/PaymentMethod/PaymentMethod"));
const EmailSettings = lazy(() => import("./ui/views/onBoarding/EmailSettings"));
const DomainsSettings = lazy(
  () => import("./ui/views/onBoarding/DomainsSettings"),
);
const ReReviewDomains = lazy(
  () => import("./ui/views/onBoarding/ReReviewDomains"),
);
// import PersonalizeBulletproof from "./ui/primitives/forms/registration/PersonalizeBulletproof";
// import LinkAccount from "./ui/views/onBoarding/LinkAccount";
// import DomainsSettings from "./ui/views/onBoarding/DomainsSettings";
// import SettingsView from "./ui/views/settingsView/SettingsView";
// import SurveyView from "./ui/views/surveyView/surveyView";
// import ContactView from "./ui/views/contactsView/contactView";
// import Profile from "./ui/views/profileView/Profile";
// import Subscription from "./ui/views/SubscriptionView/Subscription";
// import PaymentMethod from "./ui/views/SubscriptionView/PaymentMethod/PaymentMethod";
// import EmailSettings from "./ui/views/onBoarding/EmailSettings";
// import ReReviewDomains from "./ui/views/onBoarding/ReReviewDomains";

const tagManagerArgs = {
  gtmId: "GTM-WDCBZZL",
};
const mixpanelToken = process.env.REACT_APP_MIXPANEL_KEY!;
mixpanel.init(mixpanelToken);
TagManager.initialize(tagManagerArgs);

function App() {
  const [refetch, setRefetch] = useState(true);
  const { width } = useWindowSize();

  // Track page view when component mounts
  useEffect(() => {
    mixpanel.track("Page View", {
      page: window.location.pathname,
    });
  }, []);

  return (
    <>
      {width && width > 639 && (
        <div>
          <Toaster
            position="top-right"
            containerStyle={{ marginTop: "4rem", marginRight: "0.5rem" }}
            toastOptions={{
              success: {
                iconTheme: {
                  primary: "#63A375",
                  secondary: "white",
                },
              },
            }}
          />
        </div>
      )}
      {width && width <= 639 && (
        <div>
          <Toaster
            position="top-center"
            containerStyle={{ marginTop: "4rem" }}
            toastOptions={{
              success: {
                iconTheme: {
                  primary: "#63A375",
                  secondary: "white",
                },
              },
            }}
          />
        </div>
      )}
      <Dialog />
      <div className="bg-white h-screen">
        <Suspense
          fallback={
            <div className="absolute h-screen w-screen flex justify-center items-center z-10 bg-white">
              <Loader />
            </div>
          }
        >
          <Routes>
            {/* PUBLIC */}
            <Route path="/" element={<PublicRoute />}>
              <Route
                path="/"
                element={<Navigate to="/app/email-accounts" replace />}
              />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Signup />} />
              <Route path="/personalize" element={<PersonalizeBulletproof />} />
              <Route path="/forgot-password" element={<ForgetPassword />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route
                path="/survey/:formID/:accountID/:contactID"
                element={<SurveyForm />}
              />
              {/* <Route path="/waitlist" element={<Waitlist />} /> */}
              <Route path="/DataInfo" element={<ConnectionProblem />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="/waitlist-survey" element={<WaitlistSurvey />} />
              <Route path="/feedback" element={<DeleteAccountSurvey />} />
              <Route path="/500" element={<SomethingWrong />} />
              <Route
                path="/invalid-permissions"
                element={<ConnectionProblem />}
              />
              <Route path="/icf2023/contact-form" element={<ICFSurvey />} />
              <Route path="/message-delivered" element={<MessageDelivered />} />
            </Route>

            {/* PRIVATE */}
            <Route path="app" element={<PrivateRoute />}>
              <Route path="connect-account" element={<LinkAccount />} />
              <Route path="merge/:id" element={<NewPlatformRedirect />} />
              <Route path="email-settings/:id" element={<EmailSettings />} />
              <Route path="domains/:id" element={<DomainsSettings />} />
              <Route path="email-accounts" element={<SettingsView />} />
              <Route path="profile" element={<Profile />} />
              <Route path="requests" element={<SurveyView />} />
              <Route path="approved-sender-list" element={<ContactView />} />
              <Route path="reReviewDomains" element={<ReReviewDomains />} />
              {/* <Route path="component-library" element={<ComponentLibrary />} /> */}
              <Route path="subscription" element={<Subscription />} />
              <Route path="payment" element={<PaymentMethod />} />
              <Route path="delete-account" element={<DeleteAccount />} />
            </Route>
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
        </Suspense>
      </div>
    </>
  );
}

export default App;
