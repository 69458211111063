import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { PRIVATE_MENUBAR_ITEMS } from "../../../constants/constants";
import useCustomStripe from "../../../hooks/useCustomStripe";
import Loader from "../../primitives/Loader/Loader";
import BpiNavbar from "../landingPage/components/BpiNavbar";
import TrailWarningBanner from "../landingPage/components/TrailWarningBanner";
import { fetcher } from "../../../logic/util/hooks";

interface PlatformData {
  ACCOUNT_REF: string;
  id: string;
  eval_paused: boolean;
  has_send_survey_enabled: boolean;
  is_domain_review_complete: boolean;
  is_folder_settings_set: boolean;
  is_request_form_settings_set: boolean;
  is_video_step_complete: boolean;
  email: string;
  platformID: string;
  platform: string;
  status: string;
  connected_since: string;
}

interface Domain {
  ACCOUNT_REF: string;
  PARENT_REF: string | null;
  id: string;
  status: "approved" | "blocked";
  value: string;
}

interface DomainType {
  key: string;
  name?: string;
  email: string;
  phone: string;
  reason: string;
  relationship: string;
  contactID: string;
  platform_ref: string;
  option?: any;
  status?: string;
  domain?: string;
  parentKey?: string;
  count?: number;
  subject: string;
  subDomains?: any;
  platformID?: string;
  parentDomain?: string;
}

interface DataType {
  status?: string | null;
  list: DomainType[];
  total?: number;
}

const showBannerRoutes = [
  "email-accounts",
  "requests",
  "approved-sender-list",
  "profile",
].map((item) => `/app/${item}`);
const hideNavbarRoutes = [
  "email-settings",
  "connect-account",
  "domains",
  "subscription",
  "payment",
  "delete-account",
];

const PrivateRoute = () => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const { isLoading } = useSelector((state: any) => state.loader);
  const { state, reducerState, isLoadingFlag } = useCustomStripe();
  const { user } = useSelector((state: any) => state.auth);
  let [searchParams] = useSearchParams();
  const { paymentStatusObject } = state;
  let location = useLocation();

  // get state indicating whether email settings have been configured for initial platform. This is used for onboarding banner UI logic.
  const [platformData, setPlatformData] = useState<PlatformData | null>(null);
  const isRequestFormEnabled = platformData?.has_send_survey_enabled;
  const isRequestFormSettingsSet = platformData?.is_request_form_settings_set;
  const isFolderSettingsSet = platformData?.is_folder_settings_set;
  const isDomainReviewComplete = platformData?.is_domain_review_complete;
  const isVideoStepComplete = platformData?.is_video_step_complete;

  const dispatch = useDispatch();
  const { refetchBanner } = useSelector((state: any) => state.util);

  const [isOnboardBannerDismissed, setIsOnboardBannerDismissed] =
    useState<boolean>(false);

  useEffect(() => {
    if (user?.id) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'page_view',
        'user_id': user.id,
        'pathname': location.pathname
      });
    }
  }, [location.pathname, user?.id])

  useLayoutEffect(() => {
    const savedIsOnboardBannerDismissed = localStorage.getItem(
      "isOnboardBannerDismissed",
    );
    if (savedIsOnboardBannerDismissed !== null) {
      setIsOnboardBannerDismissed(JSON.parse(savedIsOnboardBannerDismissed));
    }
  }, [refetchBanner]);

  useLayoutEffect(() => {
    fetcher(`/resources/platforms/${user?.id}`)
      .then((response) => {
        console.log("fetch: success");
        const initialPlatform = response.filter(
          (platform: PlatformData) => platform.email === user?.email,
        )[0];
        setPlatformData(initialPlatform);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => { });
  }, [user?.id, user?.email]);

  // logic for calculating how many settings still haven't been configured. This is needed for the bubble counter in the onboarding banner UI.
  const numSettingsOutstanding = useMemo(() => {
    if (isRequestFormEnabled && isRequestFormSettingsSet) {
      return [
        isRequestFormSettingsSet,
        isDomainReviewComplete,
        isVideoStepComplete,
      ].filter((item) => !item).length;
    }
    return [
      isRequestFormSettingsSet,
      isFolderSettingsSet,
      isDomainReviewComplete,
      isVideoStepComplete,
    ].filter((item) => !item).length;
  }, [
    isRequestFormSettingsSet,
    isRequestFormEnabled,
    isFolderSettingsSet,
    isDomainReviewComplete,
    isVideoStepComplete,
  ]);
  // console.log({ numSettingsOutstanding });
  // console.log({ isFolderSettingsSet });

  useLayoutEffect(() => {
    const {
      freeTrialActive,
      isWhiteListed,
      welcomeBack,
      paymentMethodAttachedFreeTrial,
      cardExpired,
      cardAboutToExpire,
      bill_status,
      plan,
      trial_days,
    } = Object(paymentStatusObject);

    // console.log({ trial_days });
    // console.log({ paymentStatusObject });

    const trialExpired =
      bill_status === false && plan === "trial" && trial_days < 1;

    if (isWhiteListed) {
      setShowBanner(false);
    } else if (showBannerRoutes.includes(location.pathname)) {
      if (
        freeTrialActive ||
        paymentMethodAttachedFreeTrial ||
        welcomeBack ||
        cardAboutToExpire ||
        cardExpired ||
        trialExpired
      ) {
        // setShowBanner(true);
        if (
          trial_days <= 15 ||
          numSettingsOutstanding === 0 ||
          isOnboardBannerDismissed
        ) {
          setShowBanner(true);
        } else if (trial_days > 15 && numSettingsOutstanding > 0) {
          setShowBanner(true);
        }
      }
    } else if (!showBannerRoutes.includes(location.pathname)) {
      setShowBanner(false);
    }
  }, [
    location.pathname,
    setShowBanner,
    paymentStatusObject,
    numSettingsOutstanding,
    isOnboardBannerDismissed,
  ]);

  return !!user ? (
    <div className="h-full">
      {/** If trail period runnning or if the credit card has expired we will show this banner */}
      {showBanner && (
        <div>
          <TrailWarningBanner
            paymentStatusObject={paymentStatusObject}
            reducerState={reducerState}
          />
        </div>
      )}
      {!hideNavbarRoutes.some(route => location.pathname.includes(route)) && (
        <BpiNavbar
          items={PRIVATE_MENUBAR_ITEMS}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {!openDrawer && (
        <div className={`${isLoading ? "hidden" : "h-full"}`}>
          <Outlet context={[paymentStatusObject, reducerState]} />
        </div>
      )}
      <div
        className={`${isLoading || isLoadingFlag
          ? "flex flex-col justify-center items-center h-screen w-full"
          : "hidden"
          }`}
      >
        {" "}
        <Loader />{" "}
      </div>
    </div>
  ) : (
    <Navigate
      to={`/login${searchParams.get("redirect")
        ? `?redirect=${searchParams.get("redirect")}`
        : ""
        }`}
    />
  );
};

export default PrivateRoute;
