import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

const Footer: React.FC = () => {
  const [is404, setIs404] = useState(false);
  const location = useLocation();
  const locationMemo = useMemo(() => location, [location]);
  const isIKnowThisPersonPage = location.pathname === "/message-delivered";

  useEffect(() => {
    if (locationMemo.pathname === "/404") {
      setIs404(true);
    } else {
      setIs404(false);
    }
  }, [locationMemo]);

  return (
    <div
      className={`py-4 sm:px-24 lg:px-52 xlFooter:px-24 px-8 ${isIKnowThisPersonPage ? "bg-white" : "bg-green_bg"} w-full mt-2 sm:mt-0`}
    >
      <div className="flex gap-x-6 flex-col sm:flex-row w-full justify-between">
        <div className="font-custom font-bold text-base text-black100 w-full sm:w-auto pb-4 sm:pb-0 sm:place-self-center xlFooter:place-self-auto">
          Need help?
        </div>
        <div className="sm:flex flex-col sm:max-w-280 max-w-full pb-3 sm:pb-0 hidden">
          <div className="font-custom font-normal text-sm text-black100 w-full sm:hidden xlFooter:block">
            Visit our guide page for <br /> short video tutorials.
          </div>
          <a
            href="https://www.bulletproofinbox.com/new-member-onboarding-guide"
            target={"_blank"}
            rel="noreferrer"
            className="font-custom font-normal text-base text-green_primary underline px-1 py-2"
          >
            New member onboarding guide
          </a>
        </div>
        <div className="flex flex-col max-w-173 xs:max-w-full xs:pb-3">
          <div className="font-custom font-normal text-sm text-black100 w-full sm:hidden xlFooter:block">
            Visit our FAQ page for commonly asked questions.
          </div>
          <a
            href="https://www.bulletproofinbox.com/frequently-asked-questions"
            target={"_blank"}
            rel="noreferrer"
            className="font-custom font-normal text-base text-green_primary underline px-1 py-2"
          >
            Browse our FAQs
          </a>
        </div>
        <div className="flex flex-col max-w-280 xs:max-w-full xs:pb-3">
          <div className="font-custom font-normal text-sm text-black100 w-full sm:hidden xlFooter:block">
            Email us at support@bulletproofinbox.com or give us a call at (210)
            802-1956 for assistance.
          </div>
          <a
            href="https://www.bulletproofinbox.com/contact"
            target={"_blank"}
            rel="noreferrer"
            className="font-custom font-normal text-base text-green_primary underline px-1 py-2"
          >
            Contact us
          </a>
        </div>
        <div className="flex flex-col sm:max-w-293 max-w-full pb-3 sm:pb-0">
          <div className="font-custom font-normal text-sm text-black100 w-full sm:hidden xlFooter:block">
            We take your privacy seriously. For more details on how we protect
            your data, see below.
          </div>
          <div className="flex gap-x-4">
            <a
              href="https://www.bulletproofinbox.com/privacy-policy"
              target={"_blank"}
              rel="noreferrer"
              className="font-custom font-normal text-base text-green_primary underline px-1 py-2"
            >
              Privacy policy
            </a>
            <a
              href="https://www.bulletproofinbox.com/terms-of-service"
              target={"_blank"}
              rel="noreferrer"
              className="font-custom font-normal text-base text-green_primary underline px-1 py-2"
            >
              Terms of service
            </a>
          </div>
        </div>
        <div className="flex flex-col max-w-280 xs:max-w-full xs:pb-3 sm:hidden">
          <div className="font-custom font-normal text-sm text-black100 w-full">
            Visit our guide page for short video tutorials.
          </div>
          <a
            href="https://www.bulletproofinbox.com/new-member-onboarding-guide"
            target={"_blank"}
            rel="noreferrer"
            className="font-custom font-normal text-base text-green_primary underline px-1 py-2"
          >
            New member onboarding guide
          </a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
