import { useEffect, useState } from "react";
import "./loader.scss";

function Loader() {
  const [showStuckMessage, setShowStuckMessage] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowStuckMessage(true);
    }, 30000);
    return () => {
      setShowStuckMessage(false);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      {showStuckMessage ? (
        <p className="text-center mb-5">
          Stuck loading? Try refreshing the page.
        </p>
      ) : null}
      <div className="flex flex-col gap-4 items-center">
        <div className="loader">
          <svg viewBox="0 0 80 80">
            <circle id="test" cx="40" cy="40" r="32"></circle>
          </svg>
        </div>
      </div>
    </>
  );
}

export default Loader;
