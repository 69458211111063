import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { del, fetcher, post } from "../../../logic/util/hooks";
import Button from "../Button";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { icons } from "../../assets/icons";
import ArrowLeft from "../../assets/icons/arrow_left";
import Cross from "../../assets/icons/cross.svg";
import toast from "react-hot-toast";
interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
interface PlatformData {
  ACCOUNT_REF: string;
  id: string;
  eval_paused: boolean;
  has_send_survey_enabled: boolean;
  email: string;
  platformID: string;
  platform: string;
  status: string
}

function DeleteAccount() {
  const { state } = useLocation();
  const { paymentStatusObject, reducerState } = state;
  const { user } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isWhiteListedFlag } = reducerState;
  const { bill_status, plan } = Object(paymentStatusObject);
  const [isLoading, setIsLoading] = useState(false);
  const [platforms, setPlatforms] = useState<PlatformData[] | null>(null);
  const [selectedOption, setSelectedOption] = useState('option1');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const platformNotConnected = platforms?.some(platform => platform?.status !== 'connected');

  useLayoutEffect(() => {
    setIsLoading(true);
    fetcher(`/resources/platforms/${user?.id}?filter=all`)
      .then((response) => {
        console.log("fetch platform info: success");
        setPlatforms(response);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user?.id]);

  const handleDelete = () => {
    setIsLoading(true);
    let endpoint = `${user?.id}`;
    if (selectedOption === 'option2') {
      endpoint += "?removeBPcustomization=true";
    }
    if (isWhiteListedFlag) {
      del("accounts", endpoint)
        .then(() => {
          setIsLoading(false);
          localStorage.removeItem("sso_signup");
          navigate("/feedback");
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          Sentry.captureException(err);
        })
        .finally(() => {
          if (platforms) {
            for (const platform of platforms) {
              localStorage.removeItem(`isFolderSettingsSet_${platform.id}`);
              localStorage.removeItem(
                `isRequestFormSettingsSet_${platform.id}`,
              );
              localStorage.removeItem(`isVideoStepComplete_${platform.id}`);
              localStorage.removeItem(`inboxData_${platform.id}`);
              localStorage.removeItem(`inboxFetchError_${platform.id}`);
            }
          }
        });
    } else {
      post("stripe", `subscription/cancel/${user?.id}`)
        .then((res) => {
          console.log("Subscription Cancelled", res);
          del("accounts", endpoint)
            .then(() => {
              setIsLoading(false);
              localStorage.removeItem("jwtToken");
              navigate("/feedback");
            })
            .catch((err) => {
              setIsLoading(false);
              Sentry.captureException(err);
            });
        })
        .catch((err) => {
          console.log("Failed to cancel subscription", err);
          Sentry.captureException(err);
        })
        .finally(() => {
          if (platforms) {
            for (const platform of platforms) {
              localStorage.removeItem(`inboxData_${platform.id}`);
              localStorage.removeItem(`inboxFetchError_${platform.id}`);
              localStorage.removeItem(`isStep3Skipped_${platform.id}`);
              localStorage.removeItem(`isStep4Skipped_${platform.id}`);
              localStorage.removeItem(`isSwitchedOn_${platform.id}`);
            }
          }
          localStorage.removeItem("isOnboardBannerDismissed");
          localStorage.removeItem("sso_signup");
          localStorage.removeItem("lastPlatformCreated");
          localStorage.removeItem("isDomainsToastOn");
        });
    }
  };

  const handleOptionSelect = (option: string) => {
    if (option !== selectedOption) {
      setSelectedOption(option);
      if (option === 'option2') {
        if (platformNotConnected) {
          setIsModalOpen(true);
        }
      }
      toast.success(`Selected`)
      mixpanel.track(`Selected: '${option}'`, {
        user_Id: user?.id,
        user_Email: user?.email,
        user_FirstName: user?.first,
        user_LastName: user?.last,
        user_Phone: user?.phone,
        user_Tier: user?.tier,
      });
    }
  };

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    if (selectedOption === 'option2' && platformNotConnected) {
      setSelectedOption('option1');
    }
    setIsModalOpen(false);
  };

  const handleModalConfirm = () => {
    setIsModalOpen(false);
    handleDelete();
  };

  useEffect(() => {
    if (selectedOption) {
      window.scrollTo(0, 0);
    }
  }, []);

  const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black60 bg-opacity-50 z-20 ">
        <div className="bg-white rounded-md shadow-lg p-6 w-full md:max-w-md max-w-xs">
          <div className="flex justify-between items-start">
            <h2 className="flex text-xl font-bold mb-4">{` ${selectedOption === 'option2' && platformNotConnected ? "Warning: this option only works if your email is connected. " : "Are you sure you want to delete your account?"}`}</h2>
            <button onClick={onClose}>
              <img src={Cross} className=" w-4 h-4" />
            </button>
          </div>
          <div className="text-gray1 mb-6">
            {selectedOption === 'option2' && platformNotConnected ? (
              <>
                <span className="font-bold text-gray1">Your email is currently disconnected,</span> to ensure previously filtered emails are returned to your inbox & the Bulletproof folders are deleted, you must connect your email(s) before you delete your account. Contact {" "}
                <a
                  href="https://www.bulletproofinbox.com/contact"
                  target={"_blank"}
                  rel="noreferrer"
                  className="underline text-base text-green_primary"
                >
                  support@bulletproofinbox.com
                </a>{" "} if you have questions or concerns.
              </>
            ) : (
              "This action cannot be undone."
            )}
          </div>
          {selectedOption === 'option2' && platformNotConnected ?
            (
              <div className="flex flex-col w-full space-y-4">
                <button
                  onClick={() => {
                    mixpanel.track(`Clicked: 'Yes, delete my account' (Delete account screen)`);
                    navigate("/app/email-accounts");
                  }}
                  className="text-green_primary border border-green_primary rounded-full w-full py-2 font-semibold outline-none mt-3 md:mt-0">
                  Return to email accounts page
                </button>
                <button
                  onClick={() => {
                    mixpanel.track(`Clicked: 'Cancel' (Delete account screen)`);
                    onClose();
                  }}
                  className="text-white border border-green_primary bg-green_primary rounded-full w-full py-2 font-semibold outline-none mt-3 md:mt-0">
                  Got it
                </button>
              </div>
            ) : (
              <div className="flex flex-col md:flex-row justify-end md:space-x-4">
                <Button
                  format={{
                    id: "DeleteAccountButton",
                    type: "delete",
                    content: "Confirm Cancellation",
                    isFilled: true,
                    isLoading,
                    width: !isLoading ? "w-full" : "w-40",
                    onclick: () => {
                      mixpanel.track(`Clicked: 'Yes, delete my account' (Delete account screen)`);
                      onConfirm();
                    },
                  }}
                />
                <button
                  onClick={() => {
                    mixpanel.track(`Clicked: 'Cancel' (Delete account screen)`);
                    onClose();
                  }}
                  className="text-green_primary border border-green_primary rounded-full w-full md:w-40 py-2 font-semibold md:mx-4 outline-none mt-3 md:mt-0"> Cancel
                </button>
              </div>
            )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleModalConfirm}
      />
      <div className="grid grid-cols-2 content-center w-screen md:h-18 h-12 isolate font-custom shadow-nav m-auto bg-white sticky z-20 top-0">
        <div className="flex justify-start items-center z-20">
          <button
            className="flex items-center text-green_primary cursor-pointer text-base font-semibold underline ml-5"
            onClick={() => {
              mixpanel.track(`Clicked: 'Back' (Delete account screen)`);
              navigate("/app/profile");
            }}
          >
            <ArrowLeft color="#36854C" />
            <span className="hidden md:block ml-2">Back</span>
          </button>
        </div>
        <p className="text-black_primary text-2xl font-bold text-center sm:text-left -ml-40 md:-ml-20">Cancellation</p>
      </div>
      <div className="relative flex flex-col justify-center items-center md:mx-10 space-y-10 mt-10 px-5 md:px-0">
        <p className="text-black_primary text-xl font-bold text-center sm:text-left">
          You have a couple options for deleting your account:
        </p>
        <div className="w-full md:max-w-5xl flex flex-col gap-6 sm:gap-10 sm:flex-row items-center justify-between">
          <div className="flex flex-col shadow-nav items-start px-6 py-6 gap-6 rounded-md bg-white w-full">
            <div className="flex flex-col gap-2 flex-1">
              <p className="bg-red_error_bg py-1 px-2 rounded w-min text-red_error_dark text-xs">Recommended</p>
              <h3 className="text-black_primary text-lg font-bold">
                Turn Bulletproof off: <br />
                And leave my emails where they are.
              </h3>
              <p className="text-base text-gray1 md:pr-2">
                Bulletproof will stop filtering your email. <br />
                Previously filtered emails will stay in your Bp: Evaluating <br /> and Bp: Blocked folders.
              </p>
            </div>
            <button
              className={`${selectedOption === 'option1'
                ? "bg-red_error text-white"
                : "bg-white text-red_error border border-red_error"
                } h-10 rounded-md w-full ${isLoading ? "cursor-not-allowed opacity-50" : ""}`}
              disabled={isLoading}
              onClick={() => {
                handleOptionSelect('option1');
              }}
            >
              <div className="flex flex-row items-center justify-center gap-3">
                {selectedOption === 'option1' && (
                  <>
                    <LazyLoadImage
                      src={icons.checkWhite}
                      alt="lock icon"
                      width={15}
                    />
                  </>
                )}
                {selectedOption === 'option1' ? "Selected" : "Select"}
              </div>
            </button>
          </div>
          <div className="flex flex-col shadow-nav items-start px-6 py-6 gap-6 rounded-md bg-white w-full max-w-400">
            <div className="flex flex-col gap-2 flex-1">
              <p className="bg-white py-1 px-2 rounded w-min text-white text-xs">Blank</p>
              <h3 className="text-black_primary text-lg font-bold">
                Turn Bulletproof off: <br />
                Like it never happened!
                <span className="text-white">blank blank</span>
              </h3>
              <p className="text-base text-gray1">
                Bulletproof will stop filtering your email.<br />
                Previously filtered emails will be returned to your inbox,<br />
                and the Bulletproof folders will be deleted.
              </p>
            </div>
            <button
              className={`${selectedOption === 'option2'
                ? "bg-red_error text-white"
                : "bg-white text-red_error border border-red_error"
                } h-10 rounded-md w-full ${isLoading ? "cursor-not-allowed opacity-50" : ""}`}
              disabled={isLoading}
              onClick={() => {
                handleOptionSelect('option2');
              }}
            >
              <div className="flex flex-row items-center justify-center gap-3">
                {selectedOption === 'option2' && (
                  <>
                    <LazyLoadImage
                      src={icons.checkWhite}
                      alt="lock icon"
                      width={15}
                    />
                  </>
                )}
                {selectedOption === 'option2' ? "Selected" : "Select"}
              </div>
            </button>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:items-center justify-center">
          <p className="text-base font-semibold">Not sure which option to select? </p>
          <a
            href="https://calendly.com/jrice7733/30min"
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer ml-1 text-green_primary underline hover:no-underline"
          >
            Schedule a cancellation call
          </a>
        </div>
        <div className="w-full md:max-w-5xl flex flex-col items- justify-center bg-red_error_bg rounded-md p-4">
          <p className="text-base font-semibold">In both cases:</p>
          {plan === 'year' ? (
            <div className="flex flex-col">
              <p className="text-base">
                Deleting your account will disconnect Bulletproof from your email accounts and delete your Bulletproof account including all <br /> associated personal data.
              </p>
              <span className="mt-5">You’ll receive a prorated refund based on the monthly rate.</span>
              <p className="text-base mt-5">
                This action cannot be undone.
              </p>
            </div>
          ) : plan === 'month' ? (
            <div className="flex flex-col">
              <p className="text-base">
                Deleting your account will disconnect Bulletproof from your email accounts and delete your Bulletproof account including all <br /> associated personal data.
              </p>
              <span className="mt-5">You will not be billed again.</span>
              <p className="text-base mt-5">
                This action cannot be undone.
              </p>
            </div>
          ) : (
            <div className="flex flex-col">
              <p className="text-base">
                Deleting your account will disconnect Bulletproof from your email accounts and delete your Bulletproof account including all <br /> associated personal data.
              </p>
              <p className="text-base mt-5">
                This action cannot be undone.
              </p>
            </div>
          )}
        </div>
      </div>
      <div className={`hidden md:flex justify-center items-center sm:gap-2 xs:flex-col flex-row mt-10 pb-10`}>
        <div className={`xs:w-full xs:mt-6`}>
          <Button
            format={{
              id: "DeleteAccountButton",
              type: "delete",
              content: "Yes, delete my account",
              isFilled: true,
              isLoading,
              width: !isLoading ? "w-fit" : "w-40",
              onclick: () => {
                mixpanel.track(`Clicked: 'Yes, delete my account' (Delete account screen)`);
                handleDeleteClick();
              },
            }}
          />
        </div>
        <div className={`xs:w-full xs:-mt-3`}>
          <button
            onClick={() => {
              mixpanel.track(`Clicked: 'Cancel' (Delete account screen)`);
              navigate("/app/profile");
            }}
            className="text-red_error border border-red_error rounded-full w-40 py-2 font-semibold mr-4 outline-none"> Cancel
          </button>
        </div>
      </div>
      <div className={`sticky md:hidden bottom-0 flex w-full justify-center border-t border-gray_Xlight h-20 md:justify-between items-center bg-white100 mt-20`}>
        <Button
          format={{
            id: "DeleteAccountButton",
            type: "delete",
            content: "Yes, delete my account",
            isFilled: true,
            isLoading,
            width: !isLoading ? "w-fit" : "w-40",
            onclick: () => {
              mixpanel.track(`Clicked: 'Yes, delete my account' (Delete account screen)`);
              handleDeleteClick();
            },
          }}
        />
        <button
          onClick={() => {
            mixpanel.track(`Clicked: 'Cancel' (Delete account screen)`);
            navigate("/app/profile");
          }}
          className="text-red_error border border-red_error rounded-full w-28 md:w-40 py-2 font-semibold mx-4 outline-none"> Cancel
        </button>
      </div>
    </div>
  );
}

export default DeleteAccount;
